<template>
  <div class="sub-header">
    <div class="container__logo">
      <a class="logo" href="/">
        <img :src="require('../assets/Logo (1).svg')" />
      </a>
      <div class="lang">
        <router-link
           class="link"
          :to="switchLocale('ua')"
          :class="{ active: activeLang === 'ua' }"
          @click="changeLang('ua')"
        >
          <span>UA</span>
        </router-link>
        <router-link
         class="link"
          :to="switchLocale('ru')"
          :class="{ active: activeLang === 'ru' }"
          @click="changeLang('ru')"
        >
          <span>RU</span>
        </router-link>
      </div>
    </div>
    <div class="container__select">
      <div class="buttons">
        <button @click="showNumbers()">
          <img :src="require('../assets/phone.svg')" />
        </button>
        <button  @click="showBlock = !showBlock">
          <img :src="require('../assets/Frame 26760.svg')" />
        </button>
      </div>

      <div class="active-numbers" v-if="numbers === true">
        <div class="number">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="0.5" width="24" height="24" rx="12" fill="#F2F4F5" />
            <g clip-path="url(#clip0_22_1347)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.21108 6.37217C8.96413 5.61913 10.2095 5.70764 10.8485 6.55961L11.9092 7.97382C12.4317 8.67053 12.3624 9.64544 11.7466 10.2613L10.9646 11.0432C11.0404 11.2405 11.2899 11.6734 12.0496 12.4331C12.8094 13.1929 13.2422 13.4423 13.4395 13.5181L14.2215 12.7361C14.8373 12.1203 15.8122 12.051 16.5089 12.5736L17.9231 13.6342C18.7751 14.2732 18.8636 15.5186 18.1106 16.2717C17.8658 16.5165 17.8243 16.5579 17.4158 16.9665C16.9994 17.3829 16.1152 17.7721 15.2201 17.811C13.8193 17.8719 11.9166 17.2499 9.57475 14.908C7.23285 12.5661 6.61087 10.6634 6.67177 9.26264C6.70565 8.48342 6.95339 7.62759 7.51953 7.07024C7.92483 6.65842 7.97754 6.60571 8.21108 6.37217ZM7.83734 9.31331C7.79512 10.2843 8.20433 11.8877 10.3997 14.083C12.5951 16.2784 14.1985 16.6876 15.1694 16.6454C16.0759 16.606 16.5621 16.168 16.5908 16.1415L17.2856 15.4467C17.5366 15.1957 17.5071 14.7806 17.2231 14.5676L15.8089 13.5069C15.5767 13.3327 15.2517 13.3558 15.0464 13.5611C14.7372 13.8704 14.5261 14.0846 14.1128 14.4963C13.2544 15.3515 11.7849 13.8183 11.2247 13.2581C10.7098 12.7432 9.14086 11.2271 9.98561 10.3724C9.98728 10.3707 10.2148 10.1431 10.9217 9.4363C11.1269 9.23103 11.15 8.90605 10.9758 8.67382L9.91519 7.25961C9.7022 6.97562 9.28705 6.94611 9.03604 7.19713C8.80501 7.42816 8.54355 7.68962 8.34193 7.89263C7.92696 8.31047 7.85954 8.80255 7.83734 9.31331Z"
                fill="#23262F"
              />
            </g>
            <defs>
              <clipPath id="clip0_22_1347">
                <rect
                  width="14"
                  height="14"
                  fill="white"
                  transform="translate(5.5 5)"
                />
              </clipPath>
            </defs>
          </svg>
          <a :href="'tel:' + '0800339754'" class="info-link"
                >0 800 339 754</a
              >
        </div>
        <div class="number">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="0.5" width="24" height="24" rx="12" fill="#F2F4F5" />
            <g clip-path="url(#clip0_22_1351)">
              <path
                d="M10.0557 4.60328C12.0648 3.80673 14.4051 3.86183 16.3606 4.78699C15.8034 4.70278 15.2366 4.76627 14.685 4.85903C13.187 5.14045 11.7634 5.8416 10.6506 6.88321C9.58367 7.92647 8.83599 9.31068 8.62484 10.7916C8.48513 11.8409 8.61459 12.9416 9.08797 13.8967C9.57573 14.8988 10.4344 15.7207 11.4693 16.1449C12.4664 16.5646 13.6232 16.56 14.6333 16.1853C16.1488 15.6321 17.2378 14.1381 17.3623 12.5421C17.4406 11.496 17.1849 10.3832 16.4783 9.58091C15.8039 8.79555 14.8175 8.36298 13.8304 8.11676C13.7775 7.14834 14.2361 6.1916 14.9635 5.56183C15.3674 5.19949 15.8687 4.96857 16.3871 4.82071L16.4264 4.80722C17.9104 5.51512 19.1713 6.68255 19.9747 8.11578C20.663 9.33601 21.022 10.7421 20.9864 12.1427C20.9797 13.9539 20.2991 15.7472 19.1324 17.1321C18.0293 18.4528 16.4863 19.4028 14.8046 19.7832C13.1184 20.1702 11.3042 20.0033 9.7262 19.2904C8.18205 18.6044 6.87385 17.4118 6.04167 15.9449C5.34938 14.7258 4.98314 13.3202 5.01356 11.9179C5.01687 10.1707 5.64055 8.43699 6.7295 7.07071C7.59938 5.97976 8.75331 5.11232 10.0557 4.60328Z"
                fill="#E60000"
              />
              <path
                d="M14.685 4.85897C15.2366 4.76621 15.8034 4.70272 16.3606 4.78693L16.4406 4.80042L16.3871 4.82065C15.8687 4.96851 15.3674 5.19943 14.9635 5.56177C14.2361 6.19154 13.7774 7.14828 13.8304 8.1167C14.8175 8.36292 15.8039 8.79548 16.4783 9.58085C17.1849 10.3831 17.4406 11.496 17.3623 12.542C17.2378 14.1381 16.1488 15.632 14.6333 16.1853C13.6232 16.56 12.4664 16.5646 11.4692 16.1448C10.4344 15.7206 9.57573 14.8988 9.08796 13.8966C8.61458 12.9415 8.48512 11.8409 8.62483 10.7915C8.83598 9.31061 9.58366 7.9264 10.6506 6.88315C11.7634 5.84154 13.187 5.14038 14.685 4.85897Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_22_1351">
                <rect
                  width="16"
                  height="16"
                  fill="white"
                  transform="translate(5 4)"
                />
              </clipPath>
            </defs>
          </svg>
          <a :href="'tel:' + '+380504746751'" class="info-link"
                >050 47 46 751</a
              >
        </div>
        <div class="number">
          <div class="icons">
            <img :src="require('../assets/image 8 (2).svg')" />
          </div>
          <a :href="'tel:' + '+380734746751'" class="info-link"
                >073 47 46 751</a
              >
        </div>
        <div class="number">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="0.5" width="24" height="24" rx="12" fill="#F2F4F5" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.5 5.11108C12.9909 5.11108 13.3889 5.50905 13.3889 5.99997V9.2222C13.3889 9.71311 12.9909 10.1111 12.5 10.1111C12.0091 10.1111 11.6111 9.71311 11.6111 9.2222V5.99997C11.6111 5.50905 12.0091 5.11108 12.5 5.11108Z"
              fill="#00A0FF"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.2631 10.0248C19.4148 10.4917 19.1593 10.9932 18.6924 11.1449L15.6279 12.1406C15.161 12.2923 14.6595 12.0368 14.5078 11.5699C14.3561 11.103 14.6116 10.6015 15.0785 10.4498L18.143 9.45413C18.6099 9.30242 19.1114 9.55793 19.2631 10.0248Z"
              fill="#00A0FF"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.6798 17.9751C16.2826 18.2637 15.7268 18.1756 15.4382 17.7785L13.5442 15.1716C13.2557 14.7745 13.3437 14.2186 13.7409 13.93C14.138 13.6415 14.6939 13.7295 14.9825 14.1267L16.8765 16.7335C17.165 17.1307 17.077 17.6866 16.6798 17.9751Z"
              fill="#00A0FF"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.3202 17.9751C7.92303 17.6865 7.83499 17.1306 8.12355 16.7335L10.0175 14.1266C10.3061 13.7295 10.862 13.6414 11.2591 13.93C11.6563 14.2186 11.7443 14.7744 11.4558 15.1716L9.5618 17.7784C9.27324 18.1756 8.71736 18.2636 8.3202 17.9751Z"
              fill="#00A0FF"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.73693 10.0247C5.88863 9.55784 6.3901 9.30233 6.857 9.45403L9.92151 10.4498C10.3884 10.6015 10.6439 11.1029 10.4922 11.5698C10.3405 12.0367 9.83904 12.2922 9.37215 12.1405L6.30763 11.1448C5.84074 10.9931 5.58523 10.4916 5.73693 10.0247Z"
              fill="#00A0FF"
            />
          </svg>
          <a :href="'tel:' + '+380984746751'" class="info-link"
                >098 47 46 751</a
              >
        </div>
      </div>
    </div>

    <div class="container__order-btn">
      <button class="order-call" @click="scrollToForm">
        <span>{{ $t("orderAcall") }} </span>
      </button>
      <button class="order-estimate" @click="closeSendPrice">
        <span>{{ $t("orderAnEstimate") }}</span>
      </button>
    </div>
    <SendPriceInfoModal @closeModal="closeSendPrice" :showModal="sendPrice" :succesBlock="succesBlock"  @succes="sendForm"></SendPriceInfoModal>
    <SuccesBlock :showSucces="succesBlockVal"
      @closeSucces="closeSuccesBlock"  @services="closeSuccesBlock"></SuccesBlock>

  </div>

  <div class="menu-mobile" v-if="showBlock">
    <MobileMenu />
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import SendPriceInfoModal from "./SendPriceInfoModal.vue";
import SuccesBlock from "../components/SuccesBlock.vue";
import MobileMenu from '../components/MobileMenu.vue';

export default {
  components:{SendPriceInfoModal,SuccesBlock, MobileMenu},
  setup() {
    const showBlock = ref(false)
    const activeLang = ref("ua");
    const { locale } = useI18n();
    // const router = useRouter();
    const route = useRoute();
    const sendPrice = ref(false)
    const language = ref(["ua", "ru"]);
    const succesBlock = ref(false)
    const succesBlockVal = ref(false)
    const sendForm = () =>{
      sendPrice.value = false
      succesBlockVal.value = true
    }
    function changeLang(lang) {
      document.documentElement.lang = lang;
      activeLang.value = lang;
        locale.value = lang;
        if(lang  === 'ua'){
          document.querySelector('meta[name="description"]').setAttribute('content', 'Ser-ezhik.com.ua – надійний партнер у світі переїздів. Ми пропонуємо широкий спектр послуг з переїзду квартир, будинків, офісів та бізнесів в Україні. Найкращі фахівці, найсучасніша техніка та безперервний підхід – ваш комфорт та надійність у нас на першому місці. Замовте професійний переїзд вже сьогодні!');
          document.title = ' Професійні Переїзди Квартир, Будинків та Офісів в Україні | Ser-ezhik.com.ua'
        }if(lang === 'ru'){
          document.querySelector('meta[name="description"]').setAttribute('content', "Ser-ezhik.com.ua – надежный партнер в мире переездов. Мы предлагаем широкий спектр услуг по переезду квартир, домов, офисов и бизнеса в Украине. Лучшие специалисты, самое современное оборудование и непрерывный подход – ваш комфорт и надежность у нас на первом месте. Закажите профессиональный переезд уже сегодня!");
          document.title = 'Профессиональные переезды квартир, домов и офисов в Украине | Ser-ezhik.com.ua'
        }
    
    }
    const closeSuccesBlock = () => {
      succesBlockVal.value = !succesBlockVal.value

    }
    const closeSendPrice = () => {
      sendPrice.value = !sendPrice.value
    }
    onMounted(() => {
      if (route.params.slug) {
        activeLang.value = route.params.slug;
        locale.value = route.params.slug;
      }
     
    });

    watch(
      () => route.params.slug,
      (newValue) => {
        if(newValue){

          locale.value = newValue;
          activeLang.value = newValue;
        }
        if(activeLang.value  === 'ua'){
          document.querySelector('meta[name="description"]').setAttribute('content', 'Ser-ezhik.com.ua – надійний партнер у світі переїздів. Ми пропонуємо широкий спектр послуг з переїзду квартир, будинків, офісів та бізнесів в Україні. Найкращі фахівці, найсучасніша техніка та безперервний підхід – ваш комфорт та надійність у нас на першому місці. Замовте професійний переїзд вже сьогодні!');
          document.title = ' Професійні Переїзди Квартир, Будинків та Офісів в Україні | Ser-ezhik.com.ua'
        }if(activeLang.value === 'ru'){
          document.querySelector('meta[name="description"]').setAttribute('content', "Ser-ezhik.com.ua – надежный партнер в мире переездов. Мы предлагаем широкий спектр услуг по переезду квартир, домов, офисов и бизнеса в Украине. Лучшие специалисты, самое современное оборудование и непрерывный подход – ваш комфорт и надежность у нас на первом месте. Закажите профессиональный переезд уже сегодня!");
          document.title = 'Профессиональные переезды квартир, домов и офисов в Украине | Ser-ezhik.com.ua'
       
        }
      }
    );

    const numbers = ref(false);

    function showNumbers() {
      numbers.value = !numbers.value;
    }

    const switchLocale = (locale) => {
       if(locale === 'ua'){
        
        return '/'
       }else{

         return `/${locale}`;
       }
    };

    const scrollToForm = () => {
      const element = document.getElementById('order-form');
      if (element) {
        const headerOffset = 120;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    };

    return {
      activeLang,
      switchLocale,
      // locale,
      // router,
      // route,
      sendPrice,
      language,
      changeLang,
      numbers,
      showNumbers,
      showBlock,
      closeSendPrice,
      succesBlock,
      sendForm,
      succesBlockVal,
      closeSuccesBlock,
      scrollToForm,
    };
  },
};
</script>

<style lang="scss">
        .info-link {
          white-space: nowrap;
          color: #000;
          font-family: "Manrope", sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          text-decoration: none;
        }
.link{
  color: #000;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 16px;
text-decoration: none;
}
.sub-header {
  margin: 0;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .logo {
    cursor: pointer;

    img {
      width: 100%;
    }
  }

  .container {
    box-sizing: border-box;
    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 20px;
    }
    &__select {
      display: flex;
      flex-direction: column;

      .buttons {
        display: flex;
        button {
          cursor: pointer;
          background: white;
          border: none;
        }
      }
      .active-numbers {
        position: absolute;
        z-index: 1;
        top: 57px;
        right: 0px;
        width: 174px;
        border-radius: 24px;
        border: 1px solid #e6e8ec;
        background: #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        .number {
          display: flex;
          align-items: center;
          gap: 10px;
          .icons {
            cursor: pointer;
            border-radius: 40px;
            background: #f2f4f5;
            display: flex;
            height: 24px;
            width: 24px;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: space-evenly;
            align-content: center;
          }
          span {
            color: #000;
            font-family: "Manrope", sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
          }
        }
      }
    }
    &__logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 36px;
      .lang {
        display: flex;
        gap: 5px;

        .link {
          padding: 4px;
          border-radius: 3px;
        }

        button {
          cursor: pointer;
          border: none;
          background: white;
          padding: 5px;
          span {
            color: #000;
            font-family: "Manrope", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
          }
          &.active {
            span {
              font-weight: 700;
            }
          }
        }
      }
    }
    &__order-btn {
      display: flex;
      gap: 24px;
      .order-call {
        cursor: pointer;
        display: flex;
        width: 178px;
        padding: 12px 16px;
        justify-content: center;
        align-items: center;
        border-radius: 90px;
        background: #23262f;
        border: 1px solid #23262f;
        span {
          color: #fcfcfd;
          text-align: center;
          font-family: "Manrope", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
        }
      }

      .order-estimate {
        cursor: pointer;
        display: flex;
        width: 178px;
        padding: 12px 16px;
        justify-content: center;
        align-items: center;
        border-radius: 90px;
        background: white;
        border: 1px solid #e6e8ec;
        span {
          color: #202325;
          text-align: center;
          font-family: "Manrope", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
        }
      }
    }
  }
}

@media (max-width: 1499px) {
}
@media (max-width: 1350px) {
  .sub-header .container {
    padding-left: 37px;
    padding-right: 37px;
  }
}

@media (min-width: 770px) {
  .sub-header .container__select {
    display: none;
  }
}

@media (max-width: 770px) {
  .sub-header .container__order-btn {
    display: none;
  }
  .sub-header .container .container__logo .lang {
    display: none;
  }
  .sub-header {
    flex-direction: row;
    align-items: center;
    padding: 10px 0;
  }
  .sub-header .container {
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
  }
  .sub-header .container__select .buttons {
    margin-left: 10px;
  }
}

@media (max-width: 430px) {
  .sub-header {
    .logo {
      max-width: 160px;
      min-width: 100px;
    }
  }
}
</style>
