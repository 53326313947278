<template>
  <section class="container-subscribe">
    <div class="subscribe">
      <div class="subscribe__info">
        <div class="title">
          <h2>{{ $t("subscrab") }}</h2>
        </div>
        <div class="text">
          <span>{{ $t("subscrabText") }}</span>
        </div>
      </div>
      <form @submit.prevent="submitForm">
        <div class="subscribe__search-input" :class="{ 'error': validationError }">
          <input
            type="email"
            id="email"
            v-model="email"
            :placeholder="$t('subscrabPlaceholder')" 
          />
          <span class="error-message">{{ validationError }}</span>
          <button type="submit">
            <span>{{ $t("welcomeMessage") }}</span>
          </button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { useI18n } from "vue-i18n";
import { ref } from 'vue';
import axios from 'axios';
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "SubscribeBlock",
  setup() {
    const { t } = useI18n();
    const email = ref('');
    const validationError = ref(null);

    const submitForm = async () => {
      validationError.value = null;

      // Валідація email
      if (!validateEmail(email.value)) {
        validationError.value = t('invalidEmail');
        return;
      }

      try {
        const message = `📧 Нова підписка на розсилку!\n\nEmail: ${email.value}\nДата: ${new Date().toLocaleString()}`;
        
        await axios.post(`https://api.telegram.org/bot6685662973:AAHI-9ndigaQqW7ur9578KR_VOolSYr5L0Q/sendMessage`, {
          chat_id: "-4094200753",
          text: message,
          parse_mode: 'HTML'
        });

        // Очищаємо форму після успішної відправки
        email.value = '';
        toast.success(t('subscriptionSuccess'), {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } catch (error) {
        console.error('Помилка відправки в Telegram:', error);
        validationError.value = t('errorSending');
      }
    };

    const validateEmail = (email) => {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    };

    return {
      t,
      email,
      validationError,
      submitForm,
    };
  },
};
</script>

<style lang="scss">
.container-subscribe {
  // max-width: 1200px;
  //   margin: 0 auto;
}
.subscribe {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 60px 0px;
  hr {
    width: 100%;
    background-color: #f2f4f5;
    height: 1px;
    border: none;
    background: #f2f4f5;
  }
  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    .title {
      h2 {
        color: #000;
        text-align: center;
        font-family: "Manrope", sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
      }
    }
    .text {
      display: flex;
      max-width: 604px;
      span {
        color: #9a9ea6;
        text-align: center;
        font-family: "Manrope", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
  &__search-input {
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #e3e5e5;
    background: #fff;
    position: relative;
    padding: 5px;

    &.error {
      border: 1px solid red;
    }
    
    input {
      border: none;
      outline: none;
      flex: 1;
      min-width: 354px;
      margin-left: 15px;
      font-family: "Manrope", sans-serif;
    }
    button {
      padding: 8px 25px;
      justify-content: center;
      align-items: center;
      gap: 12px;
      border: none;
      flex-shrink: 0;
      border-radius: 8px;
      background: #23262f;
      cursor: pointer;
      span {
        color: #fcfcfd;
        font-family: "Manrope", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }
    }
  }

  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
    position: absolute;
    left: 0;
    bottom: -25px;
  }
}

@media (max-width: 770px) {
  .subscribe {
    display: none;
  }
}
</style>
