<template>
    <div class="section-form-new" id="order-form">
        <div class="container">
            <div class="form-container">
                <h2>{{ t('formTitle') }}</h2>
                <form @submit.prevent="handleSubmit" class="form">
                    <div class="form-group">
                        <label for="name">{{ t('formNameLabel') }}*:</label>
                        <input 
                            type="text" 
                            id="name" 
                            v-model="formData.name"
                            :placeholder="t('formName')"
                            :class="{ 'error': errors.name }"
                        >
                        <span class="error-text" v-if="errors.name">
                            {{ errors.name }}
                        </span>
                    </div>

                    <div class="form-group">
                        <label for="phone">{{ t('formPhoneLabel') }}*:</label>
                        <VueTelInput
                            v-model="formData.phone"
                            v-bind="bindProps"
                            mode="international"
                            :default-country="'ua'"
                            :class="{ 'error': errors.phone }"
                            :validCharactersOnly="true"
                            v-mask="'+38(0##) ###-##-##'"
                        />
                        <span class="error-text" v-if="errors.phone">
                            {{ errors.phone }}
                        </span>
                    </div>

                    <div class="form-group">
                        <label for="loadingAddress">{{ t('formLoadingAddressLabel') }}*:</label>
                        <input 
                            type="text" 
                            id="loadingAddress" 
                            v-model="formData.loadingAddress"
                            :placeholder="t('formLoadingAddress')"
                            :class="{ 'error': errors.loadingAddress }"
                        >
                        <span class="error-text" v-if="errors.loadingAddress">
                            {{ errors.loadingAddress }}
                        </span>
                    </div>

                    <div class="form-group">
                        <label for="unloadingAddress">{{ t('formUnloadingAddressLabel') }}*:</label>
                        <input 
                            type="text" 
                            id="unloadingAddress" 
                            v-model="formData.unloadingAddress"
                            :placeholder="t('formUnloadingAddress')"
                            :class="{ 'error': errors.unloadingAddress }"
                        >
                        <span class="error-text" v-if="errors.unloadingAddress">
                            {{ errors.unloadingAddress }}
                        </span>
                    </div>

                    <div class="form-group checkbox-group">
                        <label>
                            <input 
                                type="checkbox" 
                                v-model="formData.needLoaders"
                            > {{ t('formNeedLoaders') }}
                        </label>
                        
                        <label>
                            <input 
                                type="checkbox" 
                                v-model="formData.needTruck"
                            > {{ t('formNeedTruck') }}
                        </label>
                    </div>

                    <div class="form-group w-10">
                        <label for="description">{{ t('formDescriptionLabel') }}:</label>
                        <textarea 
                            id="description" 
                            v-model="formData.description"
                            :placeholder="t('formDescription')"
                            rows="4"
                        ></textarea>
                    </div>

                    <div class="form-group w-10 checkbox-group-col">

                        <button type="submit" :disabled="isSubmitting">
                            {{ isSubmitting ? t('formSubmitting') : t('formSubmit') }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive } from 'vue'
import { useI18n } from "vue-i18n";
import { VueTelInput } from "vue-tel-input"
import "vue-tel-input/vue-tel-input.css"
import { toast } from "vue3-toastify"
import "vue3-toastify/dist/index.css"
import { mask } from 'vue-the-mask'
import axios from "axios"

const TELEGRAM_BOT_TOKEN = "6685662973:AAHI-9ndigaQqW7ur9578KR_VOolSYr5L0Q"
const TELEGRAM_CHAT_ID = "-4094200753"

export default {
    name: "FormNew",
    components: {
        VueTelInput,
    },
    directives: { mask },
    setup() {
        const { t } = useI18n();

        const bindProps = ref({
            mode: "international",
            inputOptions: {
                showDialCode: false,
                autofocus: false,
                placeholder: t('formPhonePlaceholder'),
            },
        });

        const formData = reactive({
            name: '',
            phone: '',
            loadingAddress: '',
            unloadingAddress: '',
            needLoaders: false,
            needTruck: false,
            description: ''
        })

        const errors = reactive({
            name: '',
            phone: '',
            loadingAddress: '',
            unloadingAddress: ''
        })

        const isSubmitting = ref(false)

        const validateForm = () => {
            let isValid = true

            // Очищення помилок
            Object.keys(errors).forEach(key => {
                errors[key] = ''
            })

            // Валідація імені
            if (!formData.name.trim()) {
                errors.name = 'Будь ласка, введіть ім\'я'
                isValid = false
            }

            // Валідація телефону
            if (!formData.phone || !formData.phone.trim()) {
                errors.phone = 'Будь ласка, введіть номер телефону'
                isValid = false
            } else if (!formData.phone.match(/^\+38\(0\d{2}\)\s\d{3}-\d{2}-\d{2}$/)) {
                errors.phone = 'Введіть коректний номер телефону'
                isValid = false
            }

            // Валідація адреси завантаження
            if (!formData.loadingAddress.trim()) {
                errors.loadingAddress = 'Будь ласка, введіть адресу завантаження'
                isValid = false
            }

            // Валідація адреси вивантаження
            if (!formData.unloadingAddress.trim()) {
                errors.unloadingAddress = 'Будь ласка, введіть адресу вивантаження'
                isValid = false
            }

            return isValid
        }

        const handleSubmit = async () => {
            if (!validateForm()) return

            isSubmitting.value = true
            
            try {
                const message = `
🆕 Нова заявка:
👤 Ім'я: ${formData.name}
📞 Телефон: ${formData.phone}
📍 Завантаження: ${formData.loadingAddress}
📍 Вивантаження: ${formData.unloadingAddress}
🏋️ Вантажники: ${formData.needLoaders ? 'Так' : 'Ні'}
🚛 Вантажне авто: ${formData.needTruck ? 'Так' : 'Ні'}
📝 Опис: ${formData.description || 'Не вказано'}
                `

                await axios.post(`https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`, {
                    chat_id: TELEGRAM_CHAT_ID,
                    text: message
                })

                toast.success('Дякуємо! Ваша заявка успішно відправлена.', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                })

                // Очищення форми
                Object.keys(formData).forEach(key => {
                    if (typeof formData[key] === 'boolean') {
                        formData[key] = false
                    } else if (key === 'phone') {
                        formData.phone = ''
                    } else {
                        formData[key] = ''
                    }
                })

            } catch (error) {
                console.error('Помилка при відправці форми:', error)
                toast.error('Виникла помилка при відправці форми. Спробуйте пізніше.', {
                    position: "top-right",
                    autoClose: 5000,
                })
            } finally {
                isSubmitting.value = false
            }
        }

        return {
            formData,
            errors,
            bindProps,
            isSubmitting,
            handleSubmit,
            t
        }
    }
}
</script>

<style lang="scss" scoped>
.form-container {
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 15px;
    margin-top: 40px;

    h2 {
        text-align: center;
        margin-bottom: 20px;
    }
}

.form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row wrap;

    .form-group {
        display: flex;
        flex-direction: column;
        width: 50%;
        box-sizing: border-box;
        padding: 0 10px;
        margin-bottom: 20px;

        label {
            margin-bottom: 5px;
        }

        &.w-10 {
            width: 100%;
        }

        @media (max-width: 730px) {
            width: 100%;
        }
    }

    .checkbox-group-col {
        flex-direction: column;
        align-items: flex-start;
    }

    .checkbox-group {
        flex-direction: row;
        align-items: center;
        gap: 10px;
    }
    .vue-tel-input {

        .vti__input {
            border: none !important;
            background: #ffffff !important;
            width: 100% !important;
            height: 100% !important;
            border-radius: 4px !important;
            padding: 0 12px;
            font-size: 16px;
        }
    }
}

input[type="text"],
textarea {
    padding: 12px 8px;
    border: 1px solid #ddd;
    border-radius: 4px;

    padding-left: 16px;
    border-radius: 5px;
    border: 1px solid #e3e5e5;
    background: #fff;
    color: var(--ink-lighter, #72777a);
    font-family: "Manrope", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    resize: none;
}

input.error {
    border-color: red;
}

.error-text {
    color: red;
    font-size: 0.8em;
}

button {
    padding: 15px 20px;
    // background-color: #4CAF50;
    // color: white;
    // border: none;
    // border-radius: 4px;
    // cursor: pointer;

    cursor: pointer;
    display: flex;
    border: none;
    justify-content: center;
    align-items: center;
    border-radius: 48px;
    background: #f58a1f;
    color: #fff;
    text-align: center;
    font-family: "Manrope", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    max-width: 250px;
    width: 100%;

    @media (max-width: 730px) {
        max-width: 100%;
    }
}

button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

:deep(.vue-tel-input) {
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
    height: 48px;
}

:deep(.vue-tel-input.error) {
    border-color: red;
}

:deep(.vti__input) {
    border: none !important;
    background: #ffffff !important;
    width: 100% !important;
    height: 100% !important;
    border-radius: 4px !important;
    padding: 0 12px;
    font-size: 16px;
}

:deep(.vti__dropdown) {
    display: none;
}

:deep(.vti__dropdown-list) {
    width: 100% !important;
}
</style>